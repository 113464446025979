import React, { useState, useEffect, Component } from "react"
import Layout from "../components/layout"
import config from "../utils/siteConfig"
import Button from "../components/Atoms/Button/Button"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faChevronRight, faArchive, faBookOpen, faCode, faCube, faBuilding } from "@fortawesome/free-solid-svg-icons"
import ContactCell from "../components/Organisms/ContactCell/ContactCell"

export default function About({ data }) {
    debugger;

    const page = data.allContentfulZAboutUsNew.edges[0].node
    const Bold = ({ children }) => <span className="bold">{children}</span>
    //const Text = ({ children }) => <p className="align-center">{children}</p>;

    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="heading1">{children}</h1>
            ),
            [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="heading2">{children}</h2>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="heading3">{children}</h3>
            ),
            [BLOCKS.HEADING_4]: (node, children) => (
                <h4 className="heading4">{children}</h4>
            ),
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="aboutParagraph">{children}</p>
            ),
            [BLOCKS.UL_LIST]: (node, children) => {
                return (
                    <ul
                        style={{
                            listStyle: 'disc',
                            lineHeight: '35px',
                            marginLeft: '1rem',
                        }}
                    >
                        {children.map((item) => (
                            <li key={item.key}>{item.props.children[0].props.children[0]}</li>
                        ))}
                    </ul>
                );
            },
            //[BLOCKS.UL_LIST]: (node, children) => (
            //    <span className="d-inline-block bulletSpan">{children}</span>
            //),

        },
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderText: (text) =>
            text
                .replace(/\u2028/g, "")
                .split("\n")
                .flatMap((text, i) => [i > 0 && <br />, text])
    }

    const {
        id,
        metaData,
        auHHd,
        auHTxt,
        auS1Hd,
        auS1Rtxt,
        auS2Hd,
        auS2Rtxt,
        auS2Rtxt2,
        auS2Rtxt3,
        auS2Txt,
        auS3Hd,
        auS3Rtxt,
        auS3Rtxt2,
        auS3Rtxt3,
        auS3Txt,
        auS4Rtxt,
        auS5Hd,
        auS5Rtxt,
        auS5Number,
        auS5Number2,
        auS5Number3,
        auS5Number4,
        auS5Txt,
        auS5Txt2,
        auS5Txt3,
        auS5Txt4,
        auS6HdLft,
        auS6HdRght,
        auS6Txt,
        auS6TxtLft,
        auS6TxtRght,
        auS7Btn,
        auS7Hd,
        auS7Rtxt,
        auS7Txt,
        auS1Image,
        auS2Image,
        auS2Image2,
        auS3Image,
        auS5Image,
    } = page

    //for responsiveness
    const [width, setWindowWidth] = useState(0)
    useEffect(() => {

        updateDimensions();

        window.addEventListener('resize', updateDimensions);
        return () =>
            window.removeEventListener('resize', updateDimensions);
    }, [])
    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    const responsive = {
        webContainer: width > 960
    }

    return (
        <Layout
            IsHeaderImageDark={false}
            navColour={"white"}>
            <head>
                <title>{metaData.title}</title>
                <meta charset="utf-8" />
                <meta name="description" content={metaData.description} />
                <meta name="keywords" content={metaData.keywords.keywords} />
                <link rel="alternate" hreflang="de" href="https://wunderloop.io/de/about" />
                <link rel="alternate" hreflang="en" href="https://wunderloop.io/en/about" />
            </head>

            {/*header*/}
            <section className="bg-white">
                <div className="sectionFit-container bg-white mt-1 mb-4 pt-5">
                    <div className="pl-5 pr-5 text-center row pt-4 justify-content-center">
                        <h1 className="align-top h1-largeMedium3 h1-darkBlue2 text-center">
                            {auHHd}
                        </h1>
                    </div>
                    <div className="pl-5 pr-5 row text-center pt-4 justify-content-center">
                        <h2 className="h2-huge2 text-darkgray2 text-center">
                            {auHTxt.auHTxt}
                        </h2>
                    </div>
                </div>
            </section>

            {/*section 1*/}
            <section className="bg-lightgray">
                <div className="sectionFit-container2 bg-lightgray">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pb-5">
                            <div className="row pt-4">
                                <h3 className="align-top h3-huge h1-darkBlue2">
                                    {auS1Hd}
                                </h3>
                            </div>
                            <div className="row pr-3 pt-2">
                                <p className="p-regular text-darkgray3 pr-5">
                                    {documentToReactComponents(auS1Rtxt.json, options)}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <div className="pt-5 pr-5 pl-4">
                                <img style={{ width: "70%" }} src={auS1Image.file.url} className="img-fluid" alt={auS1Image.title} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*section 2*/}
            {responsive.webContainer === true ? (
                <section className="bg-white">
                    <div className="sectionFit-container bg-white">
                        <div className="text-center row text-white justify-content-center text-center">
                            <h2 className="align-top h2-huge3 h1-darkBlue2 text-center">
                                {auS2Hd}
                            </h2>
                        </div>
                        <div className="row text-center pt-4 justify-content-center">
                            <p className="p-medium text-darkgray2">
                                {auS2Txt.auS2Txt}
                            </p>
                        </div>
                        <div className="row pt-5 text-center justify-content-center">
                            <div className="text-center justify-content-center">
                                <img
                                    style={{ width: "90vw" }}
                                    src={auS2Image2.file.url}
                                    className="img-fluid"
                                    alt={auS2Image2.title} />
                            </div>
                        </div>
                        <div className="row pt-5 align-items-center">
                            <div className="col-lg-6 pr-5">
                                <img
                                    src={auS2Image.file.url}
                                    className="img-fluid"
                                    alt={auS2Image.title}
                                />
                            </div>
                            <div className="col-lg-6">
                                <div className="row pt-4 pr-3">
                                    <p className="p-regular2 text-darkgray3 pb-4">
                                        {documentToReactComponents(auS2Rtxt.json, options)}
                                    </p>
                                </div>
                                <div className="row pr-3">
                                    <p className="p-regular2 text-darkgray3 pt-5 pb-4">
                                        {documentToReactComponents(auS2Rtxt2.json, options)}
                                    </p>
                                </div>
                                <div className="row pr-3">
                                    <p className="p-regular2 text-darkgray3 pt-5 pb-4">
                                        {documentToReactComponents(auS2Rtxt3.json, options)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <section className="bg-white">
                    <div className="sectionFit-container bg-white">
                        <div className="text-center row text-white justify-content-center text-center">
                            <h2 className="align-top h2-huge3 h1-darkBlue2 text-center">
                                {auS2Hd}
                            </h2>
                        </div>
                        <div className="row text-center pt-4 justify-content-center">
                            <p className="p-medium text-darkgray2">
                                {auS2Txt.auS2Txt}
                            </p>
                        </div>
                        <div className="text-center justify-content-center">
                            <img
                                style={{ width: "100vw" }}
                                src={auS2Image2.file.url}
                                className="img-fluid"
                                alt={auS2Image2.title} />
                        </div>
                        <div className="row pt-2 pr-3">
                            <p className="p-regular2 text-darkgray3 pb-4">
                                {documentToReactComponents(auS2Rtxt.json, options)}
                            </p>
                        </div>
                        <div className="row pr-3">
                            <p className="p-regular2 text-darkgray3 pt-2 pb-4">
                                {documentToReactComponents(auS2Rtxt2.json, options)}
                            </p>
                        </div>
                        <div className="row pr-3">
                            <p className="p-regular2 text-darkgray3 pt-2 pb-4">
                                {documentToReactComponents(auS2Rtxt3.json, options)}
                            </p>
                        </div>
                        <div className="text-center justify-content-center">
                            <img
                                src={auS2Image.file.url}
                                className="img-fluid"
                                alt={auS2Image.title} />
                        </div>
                    </div>
                </section>
            )}

            {/*section 3*/}
            <section className="bg-lightgray">
                <div className="sectionFit-container2 bg-lightgray">
                    <div className="text-center row justify-content-center text-center">
                        <h2 className="align-top h2-huge3 h1-darkBlue2 text-center">
                            {auS3Hd}
                        </h2>
                    </div>
                    <div className="row text-center pt-4 justify-content-center pb-2">
                        <p className="p-medium text-darkgray2">
                            {auS3Txt.auS3Txt}
                        </p>
                    </div>
                    <div className="row pt-5 linkLight">
                        <div className="col-lg-6 pb-5">
                            <div className="row pb-5">
                                <div className="col-lg-1 pb-2">
                                    <FontAwesomeIcon className="faBookOpen" color="#6772e5" icon={faBookOpen} />
                                </div>
                                <div className="col-lg-11 pt-1">
                                    <p className="p-regular2 text-darkgray3">
                                        {documentToReactComponents(auS3Rtxt.json, options)}
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-5">
                                <div className="col-lg-1 pb-2">
                                    <FontAwesomeIcon color="#87bbfd" icon={faCode} />
                                </div>
                                <div className="col-lg-11 pt-1">
                                    <p className="p-regular2 text-darkgray3">
                                        {documentToReactComponents(auS3Rtxt2.json, options)}
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-5">
                                <div className="col-lg-1 pb-2">
                                    <FontAwesomeIcon className="faCube" color="#6772e5" icon={faCube} />
                                </div>
                                <div className="col-lg-11 pt-1">
                                    <p className="p-regular2 text-darkgray3">
                                        {documentToReactComponents(auS3Rtxt3.json, options)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <div className="pt-5 pr-5 pl-5">
                                <img src={auS3Image.file.url} className="img-fluid" alt={auS3Image.title} />
                            </div>
                        </div>
                    </div>

                    <div className="row pt-5 justify-content-center align-items-center">
                        <div className="row pt-5 stripe align-items-center">
                            <div className="colBox5 text-center">
                                <FontAwesomeIcon className="faBuilding" color="#6772e5" icon={faBuilding} />
                            </div>
                            <div className="col pl-3 linkLight">
                                <p className="pl-3 p-regular2 text-darkgray">
                                    {documentToReactComponents(auS4Rtxt.json, options)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/*section 5*/}
            <section className="bg-white">
                <div className="sectionFit-container2 bg-white">
                    <div className="row pt-4">
                        <div className="pl-0 col-lg-7">
                            <h3 className="align-top h3-huge h1-darkBlue2">
                                {auS5Hd}
                            </h3>
                        </div>
                        <div className="pl-0 col-lg-5"></div>
                    </div>
                    <div className="row pt-5 pl-3 pb-4">
                        <div className="pl-0 col-lg-7">
                            <div className="row">
                                <div className="pl-0 pr-4 pb-3 col-lg-3 numberBox">
                                    <p className="p-large text-lightBlue">
                                        {auS5Number}
                                    </p>
                                    <p className="p-large2 text-darkgray3">
                                        {auS5Txt}
                                    </p>
                                </div>
                                <div className="pl-0 pr-4 pb-3 col-lg-3 numberBox">
                                    <p className="p-large text-lightBlue">
                                        {auS5Number2}
                                    </p>
                                    <p className="p-large2 text-darkgray3">
                                        {auS5Txt2}
                                    </p>
                                </div>
                                <div className="pl-0 pr-4 pb-3 col-lg-3 numberBox">
                                    <p className="p-large text-lightBlue">
                                        {auS5Number3}
                                    </p>
                                    <p className="p-large2 text-darkgray3">
                                        {auS5Txt3}
                                    </p>
                                </div>
                                <div className="pl-0 pr-4 col-lg-3 numberBox">
                                    <p className="p-large text-lightBlue">
                                        {auS5Number4}
                                    </p>
                                    <p className="p-large2 text-darkgray3">
                                        {auS5Txt4}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="pl-0 col-lg-5"></div>
                    </div>
                    <div className="row text-white pt-5">
                        <div className="col-lg-6 pb-5 pl-0 pr-5">
                            <a href="mailto:hr@wunderloop.io">
                                <div className="colBox2 row">
                                    <div className="col-lg-1">

                                    </div>
                                    <div className="col-lg-11 pt-4">
                                        <h2 className="align-top h2-large2 h1-purple pb-2">
                                            {auS6HdLft}
                                        </h2>
                                        <p className="p-regular2 text-darkgray3">
                                            {auS6TxtLft.auS6TxtLft}
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-6 pb-5 pl-4 pr-5">
                            <a href={config.navigationURL_sustainability}>
                                <div className="colBox2 row">
                                    <div className="col-lg-1">
                                    </div>
                                    <div className="col-lg-11 pt-4">
                                        <h2 className="align-top h2-large2 text-green pb-2">
                                            {auS6HdRght}
                                        </h2>
                                        <p className="p-regular2 text-darkgray3">
                                            {auS6TxtRght.auS6TxtRght}
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="row text-white pt-5 align-items-center">
                        <div className="col-lg-6 pl-0 pr-5 pt-5">
                            <h1 className="align-top h1-medium2 h1-darkBlue">
                                {auS7Hd}
                            </h1>
                            <p className="p-regular text-darkgray3 pt-1">
                                {auS7Txt.auS7Txt}
                            </p>
                        </div>
                        <div className="col-lg-6 pl-5 pr-0 pt-5">
                            <div className="subInput pt-4">
                                <ContactCell {...page} />
                            </div>
                            <h2 className="h2-medium text-darkgray pt-3">
                                {documentToReactComponents(auS7Rtxt.json, options)}
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

        </Layout >
    );
}


export const query = graphql`
            query($locale: String!) {
                allContentfulZAboutUsNew(
                    filter: {
                node_locale: {eq: $locale }
    }) {
                edges {
                node {
                id
                metaData {
                slug
                    title
            description
            keywords {
                keywords
            }
                }
            auHHd
            auHTxt {
                auHTxt
            }
            auS1Hd
            auS1Rtxt {
                json
            }
            auS1Image {
                description
                        file {
                fileName
                            url
                        }
            title
                }
            auS2Hd
            auS2Rtxt {
                json
            }
            auS2Rtxt2 {
                json
            }
            auS2Rtxt3 {
                json
            }
            auS2Txt {
                auS2Txt
            }
            auS2Image {
                description
                        file {
                fileName
                            url
                        }
            title
                }
                auS2Image2 {
                    description
                            file {
                    fileName
                                url
                            }
                title
                    }
            auS3Hd
            auS3Rtxt {
                json
            }
            auS3Rtxt2 {
                json
            }
            auS3Rtxt3 {
                json
            }
            auS3Txt {
                auS3Txt
            }
            auS3Image {
                description
                        file {
                fileName
                            url
                        }
            title
                }
            auS4Rtxt {
                json
            }

            auS5Hd
            auS5Rtxt {
                json
            }
            auS5Number
        auS5Number2
        auS5Number3
        auS5Number4
        auS5Txt
        auS5Txt2
        auS5Txt3
        auS5Txt4
            auS5Image {
                description
                        file {
                fileName
                            url
                        }
            title
                }
            auS6HdLft
            auS6HdRght
            auS6Txt
            auS6TxtLft {
                auS6TxtLft
            }
            auS6TxtRght {
                auS6TxtRght
            }
            auS7Btn
            auS7Hd
            auS7Rtxt {
                json
            }
            auS7Txt {
                auS7Txt
            }
            }
        }
    }
}
            `
